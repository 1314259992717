import { api } from '@/lib/axios/api';
import { checkEventResolved24HoursAgo } from '@/pages/PublicEvent/checkEventResolved24HoursAgo';
import { formatDateDayMonthYear, formatElapsedTime } from '@/utils/formatDate';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { removeAlertIdPrefix } from '@/utils/removeAlertIdPrefix';

export function useGetPublicEvent() {
  const { pathname } = useLocation();
  const eventId = pathname.split('/evento/')[1];

  const query = useQuery({
    queryKey: ['public-event'],
    queryFn: async () => {
      try {
        const { data: dataEvent } = await api.get(`/evento/${eventId}`);

        const event = {
          ...dataEvent.event,
          nameFormatted: removeAlertIdPrefix(dataEvent.event.name),
          history_events: dataEvent.event.history_events.map((story) => {
            return {
              ...story,
              created_in: formatDateDayMonthYear(story.created_at),
              elapsedTime: formatElapsedTime(story.created_at),
            };
          }),
        };

        const isIncidentResolved = checkEventResolved24HoursAgo({
          stories: dataEvent.event.history_events,
        });

        return { event, isIncidentResolved };
      } catch (error) {
        console.error(error);
        return { event: null };
      }
    },
  });

  return query;
}
