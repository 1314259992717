import { api } from '@/lib/axios/api';
import { formatDateWithHour } from '@/utils/formatDate';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { Request } from './types';

interface LocationState {
  idEvent: string;
}

export function useGetTicketByEvent({ searchNameEvent, currentPage }) {
  const location = useLocation() as { state: LocationState };
  const navigate = useNavigate();

  const query = useQuery({
    queryKey: ['tickets-by-event', currentPage],
    queryFn: async () => {
      if (!location.state) {
        navigate('/previous/events');
      }

      const response = await api.get<Request>(
        `/event/detail/${location.state.idEvent}/tickets?search=${searchNameEvent}&limit=10&page=1&filter`,
      );

      const { stats, event, pagination, tickets } = response.data;

      const parsedTickets = tickets.map((ticket) => {
        const isExistsProtocol = ticket.protocols.length > 0;

        return {
          ...ticket,
          service_hours: formatDateWithHour(ticket.created_at),
          protocol_id: isExistsProtocol
            ? ticket.protocols.map((protocol) => {
                return protocol.protocol_cnt_id;
              })
            : 'Sem Protocolo',
        };
      });

      return {
        totalStats: {
          ticket_count: stats.total_tickets,
          protocol_count: stats.total_protocol,
        },
        tickets: parsedTickets,
        pagination,
        event,
        isExistsTickets: tickets.length > 0,
      };
    },
  });

  return query;
}
