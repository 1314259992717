import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { AiOutlineSearch } from 'react-icons/ai';

import { usePortalEventStore } from '@store/portal-event';

export function SearchName() {
  const { nameEvent, changeNameEvent } = usePortalEventStore((state) => {
    const { nameEvent, changeNameEvent } = state;

    return { nameEvent, changeNameEvent };
  });

  return (
    <InputGroup w="21.875rem" border="transparent">
      <InputLeftElement pointerEvents="none">
        <AiOutlineSearch color="gray.300" />
      </InputLeftElement>

      <Input
        type="search"
        name="search"
        border="1px solid #E5E7EA"
        placeholder="Pesquisar por nome do evento ou ID Alerta"
        paddingEnd={0}
        _placeholder={{ color: 'gray.400' }}
        onChange={(e) => changeNameEvent(e.target.value)}
        value={nameEvent}
      />
    </InputGroup>
  );
}
