import {
  ModalStateControlState,
  Modal,
} from '@store/update-event/@types/modal-state-control';

const initialModalState: Modal = {
  federativeUnit: {
    isOpen: false,
  },
  city: {
    isOpen: false,
  },
  neighborhood: {
    isOpen: false,
  },
  deviceCnt: {
    isOpen: false,
  },
  platformEvent: {
    isOpen: false,
  },
};

export const initialModalStateControlState: ModalStateControlState = {
  modals: initialModalState,
};
