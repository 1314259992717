import { api } from '@/lib/axios/api';
import { useDetailEventStore } from '@/store/detail-event';
import { useQuery } from '@tanstack/react-query';
import { Response, Request } from './types';
import {
  formatDateDayMonthYear,
  formatDateWithHour,
  getEventStepperDate,
} from '@/utils/formatDate';

interface GetEventDetailProps {
  id: string;
  location: 'internal' | 'portal';
}

export function useGetEventDetail({ id, location }: GetEventDetailProps) {
  const { onInitializeStoreWithEventData } = useDetailEventStore(
    (state) => state,
  );

  const query = useQuery({
    queryKey: [`${location}-event-detail`, id],
    queryFn: async (): Promise<Response> => {
      if (!id) {
        return {
          event: null,
          isExistsEquipmentLocation: false,
        };
      }

      const route =
        location === 'internal' ? 'event/detail' : 'portal/event/detail';

      const response = await api.post<Request>(route, {
        event_id: id,
      });

      const { event } = response.data;

      const isExistsEquipmentLocation =
        event.cities.length >= 1 ||
        event.states.length >= 1 ||
        event.neighborhoods.length >= 1 ||
        event.device_cnt.length >= 1;

      const parsedEvent = {
        ...event,
        created_in: formatDateDayMonthYear(event.created_at),
        create_date: formatDateWithHour(event.created_at),
        history_events: event.history_events.map((history) => {
          const statusColors = {
            Resolvido: {
              background: '#AAF4C9',
              color: '#44835E',
            },
            Monitorando: {
              background: '#99CDFD',
              color: '#3F7EF8',
            },
            'Resolvido (reaberto)': {
              background: '#99CDFD',
              color: '#3F7EF8',
            },
            Default: {
              background: '#FFD0B5',
              color: '#F07F3F',
            },
          };

          const { background, color } =
            statusColors[history.status.name] || statusColors.Default;

          return {
            ...history,
            created_in: formatDateWithHour(history.created_at),
            created_time: getEventStepperDate(history.created_at),
            status: {
              ...history.status,
              background,
              color,
            },
          };
        }),
      };

      onInitializeStoreWithEventData({
        event: parsedEvent,
        isExistsEquipmentLocation,
      });

      return {
        event: parsedEvent,
        isExistsEquipmentLocation,
      };
    },
  });

  return query;
}
