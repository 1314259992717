import { api } from '@/lib/axios/api';
import { queryClient } from '@/services/queryClient';
import { useMutation } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

interface LocationState {
  idEvent: string;
}

export function useDeleteHistory() {
  const location = useLocation() as { state: LocationState };

  const mutation = useMutation({
    mutationFn: async (id: string) => {
      try {
        const response = await api.delete('history-event', {
          data: {
            id,
          },
        });

        toast.success(`${response.data.msg}`);
      } catch (error) {
        const { message } = error as Error;
        toast.success(message);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'event-detail-to-update',
          location.state,
          location.state.idEvent,
        ],
        exact: true,
      });
    },
  });

  return mutation;
}
