import {
  Box,
  Button,
  Flex,
  FormControl,
  ModalBody,
  ModalFooter,
  Text,
  Modal as ChakraModal,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';

import { CreateOrEditModalButton } from '@/components/Modal/ModalLocationEquipment/CreateOrEditModalButton';
import { ModalContent } from '@/components/Modal/ModalLocationEquipment/ModalContent';
import { FlexWrapTag } from '@/components/Modal/ModalLocationEquipment/FlexWrapTag';
import { TagButton } from '@/components/Modal/ModalLocationEquipment/TagButton';
import { useUpdateEventStore } from '@/store/update-event';

import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';

import { useAddEventDetails } from '@/hooks/queries/get-event-detail-to-update/query-updater';
import { usePlatformEventStore } from '@/store/platform-event';
import { useGetDigitalServices } from '@/hooks/queries/get-digital-services';
import { ChevronDownIcon } from '@chakra-ui/icons';

export function Modal() {
  const { queryData } = useGetEventDetailToUpdate();

  const { queryData: digitalServicesData } = useGetDigitalServices();

  const { selectedCompany, onToggleModal, modals } = useUpdateEventStore(
    (state) => state,
  );
  const { onAddNewPlatform, onSubmitPlatform } = useAddEventDetails();
  const {
    addPlatformService,
    platformEvents,
    togglePlatformSelection,
    resetPlatformEventStore,
  } = usePlatformEventStore();

  const isExistsSelectedCompany = !!selectedCompany.id;

  if (digitalServicesData === undefined) return null;

  const isSaveButtonDisabled = platformEvents.every(
    (item) => item.isSelected === false && item.isPendingAddition === false,
  );

  const handleSubmitSave = async () => {
    onSubmitPlatform();
    resetPlatformEventStore();
    onToggleModal({
      modalName: 'platformEvent',
      isOpen: false,
    });
  };

  return (
    <>
      <CreateOrEditModalButton
        data={queryData.event.device_cnt}
        onOpenModalClick={() => {
          onToggleModal({
            modalName: 'platformEvent',
            isOpen: true,
          });
        }}
        type="update"
      />

      <ChakraModal
        isOpen={modals.platformEvent.isOpen}
        onClose={() =>
          onToggleModal({
            modalName: 'platformEvent',
            isOpen: false,
          })
        }
        size="2xl"
        isCentered
      >
        <ModalContent name="Plataformas">
          <ModalBody>
            <Flex flexDirection="column">
              <FormControl display="flex" alignItems="center">
                {isExistsSelectedCompany && (
                  <Flex w="100%" gap="1.125rem">
                    <Menu>
                      {({ isOpen }) => (
                        <>
                          <MenuButton
                            transition="all 0.2s"
                            isActive={isOpen}
                            as={Button}
                            rightIcon={
                              <ChevronDownIcon
                                fontSize={'30px'}
                                color={'blue.500'}
                              />
                            }
                            w="12rem"
                            justifyItems={'start'}
                            color={'gray.700'}
                            bg={'white'}
                            _focus={{ bg: 'white' }}
                            _hover={{ bg: 'white' }}
                            _expanded={{ bg: 'white' }}
                            borderRadius="md"
                            borderWidth="1px"
                            px={'10px'}
                            py={'10px'}
                            textAlign={'left'}
                          >
                            Escolha o serviço
                          </MenuButton>

                          <MenuList height={200} overflowY="scroll" w="9rem">
                            {digitalServicesData &&
                              digitalServicesData.digital_services.map(
                                (data) => {
                                  return (
                                    <MenuItem
                                      onClick={(event) => {
                                        onAddNewPlatform({
                                          event: event,
                                          data: data,
                                        });
                                      }}
                                      key={data.id}
                                      value={JSON.stringify(data)}
                                    >
                                      {data.name}
                                    </MenuItem>
                                  );
                                },
                              )}
                          </MenuList>
                        </>
                      )}
                    </Menu>
                  </Flex>
                )}
              </FormControl>

              {platformEvents.length > 0 && (
                <Box mt="2.5rem">
                  <Text fontSize="1rem">Serviços adicionados</Text>

                  <FlexWrapTag>
                    {platformEvents.map((item) => {
                      return (
                        <TagButton
                          key={item.name}
                          name={item.name}
                          isSelected={item.isSelected}
                          isPendingAddition={item.isPendingAddition}
                          onClick={() => togglePlatformSelection(item.name)}
                        />
                      );
                    })}{' '}
                  </FlexWrapTag>
                </Box>
              )}
            </Flex>
          </ModalBody>

          <ModalFooter bg="#EBEFF2" justifyContent="space-between">
            <Button
              onClick={() => {
                resetPlatformEventStore();
                queryData.event.digital_services.forEach((item) => {
                  addPlatformService(item, false);
                });
              }}
              color="grey.700"
              lineHeight={'20px'}
              fontSize={'16px'}
            >
              Desfazer alterações
            </Button>

            <Flex gap="1rem" alignContent="end">
              <Button
                disabled={isSaveButtonDisabled}
                colorScheme="blue"
                onClick={() => {
                  handleSubmitSave();
                }}
                w="6.18rem"
                h="2rem"
                rounded="0.25rem"
                color="white"
                bg="blue.500"
                fontWeight="400"
              >
                Salvar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  );
}
