import { api } from '@/lib/axios/api';
import { useTicketEventStore } from '@/store/ticket-event';
import { formatDateWithHour } from '@/utils/formatDate';
import { useQuery } from '@tanstack/react-query';

interface Protocol {
  protocol_cnt_id: string;
}

interface Ticket {
  id: string;
  event_id: string;
  customer: {
    codigo_cliente: string;
    customer_id: string;
    personal_info: {
      name: string;
    };
  };
  platform: string;
  hit_rule: string;
  created_at: string;
  protocols: Protocol[];
  service_hours: string;
  protocol_id: string;
}

export interface TicketListParams {
  tickets: Ticket[];
  pagination: {
    to: number;
    from: number;
    total: number;
  };
}

export function useGetTickets() {
  const {
    currentPage,
    inputSearch,
    selectCompany,
    intervalDates,
    saveTickets,
  } = useTicketEventStore((state) => state);

  const query = useQuery({
    queryKey: ['ticket-list', currentPage],
    queryFn: async () => {
      const { data } = await api.get<TicketListParams>(
        `/ticket?search=${inputSearch}&limit=5&page=${currentPage}&filter=${selectCompany}&initial_date=${intervalDates.initialDate}&end_date=${intervalDates.endDate}`,
      );

      const { pagination, tickets } = data;

      const parsedTicket = tickets.map((ticket) => {
        const isExistsProtocol = ticket.protocols.length > 0;

        return {
          ...ticket,
          service_hours: formatDateWithHour(ticket.created_at),
          protocol_id: isExistsProtocol
            ? ticket.protocols.map((protocol) => {
                return protocol.protocol_cnt_id;
              })
            : 'Sem Protocolo',
        };
      });

      saveTickets(data.tickets);

      return {
        tickets: parsedTicket,
        pagination,
      };
    },
  });

  return query;
}
