import { api } from '@/lib/axios/api';
import { useQuery } from '@tanstack/react-query';
import { Request, Response } from './types';
import { calculateTotalEventsForCompany } from './calculate-event';
import { queryClient } from '@/services/queryClient';

const ONE_MINUTE = 1000 * 60;
const TEN_MINUTES = ONE_MINUTE * 10;

export function useGetStats() {
  const query = useQuery({
    queryKey: ['event-stats'],
    queryFn: async (): Promise<Response> => {
      const { data } = await api.get<Request>('/stats');

      const { general, lastThirtyDays } = data;

      const days = lastThirtyDays.map((data) => data.day);

      const totalEventsByCompany = general.totalEventsByCompanies.map((event) =>
        calculateTotalEventsForCompany({
          lastThirtyDays,
          company: event.company,
        }),
      );

      return {
        stats: general,
        charts: {
          days,
          totalEventsByCompany,
        },
      };
    },
    staleTime: TEN_MINUTES,
  });

  const queryData = queryClient.getQueryData(['event-stats']) as Response;

  return { ...query, queryData };
}
