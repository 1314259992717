import { create } from 'zustand';
import {
  DigitalService,
  PlatformEventActions,
  PlatformEventState,
} from './types';
import { initialPlatformEventState } from './initial-state';

export const usePlatformEventStore = create<
  PlatformEventState & PlatformEventActions
>((set) => ({
  ...initialPlatformEventState,
  addPlatformService: (data: DigitalService, isPendingAddition: boolean) => {
    set((state) => ({
      platformEvents: [
        ...state.platformEvents,
        {
          name: data.name,
          id:
            data.id !== undefined && data.id !== null
              ? data.id
              : data.service_id,
          isSelected: false,
          isPendingAddition:
            isPendingAddition !== undefined && isPendingAddition !== null
              ? isPendingAddition
              : false,
        },
      ],
    }));
  },

  togglePlatformSelection: (index: string) => {
    set((state) => {
      const platformEvents = state.platformEvents.map((item) => {
        if (item.name === index) {
          return {
            ...item,
            isSelected: !item.isSelected,
          };
        }
        return item;
      });

      return {
        platformEvents,
      };
    });
  },

  resetPlatformEventStore: () => {
    set(() => ({
      platformEvents: [],
    }));
  },
}));
