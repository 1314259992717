import { api } from '@/lib/axios/api';
import { queryClient } from '@/services/queryClient';
import { useQuery } from '@tanstack/react-query';
import { PlatformEventResponse } from './types';

export function useGetDigitalServices() {
  const query = useQuery({
    queryKey: ['digital-services'],
    queryFn: async (): Promise<PlatformEventResponse> => {
      const response = await api.get<PlatformEventResponse>(
        'digital-services',
        {
          params: {
            limit: 200,
          },
        },
      );
      return response.data;
    },
  });

  const queryData = queryClient.getQueryData([
    'digital-services',
  ]) as PlatformEventResponse;

  return {
    ...query,
    queryData,
  };
}
