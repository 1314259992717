import { Flex, IconButton, Text } from '@chakra-ui/react';

import { Logout } from '../../Icons/Logout';
import { signOut } from '@/utils/signOut';
import { useSessionStore } from '@/store/session';

interface LogoutBoxProps {
  collapse: boolean;
}

export function LogoutBox({ collapse }: LogoutBoxProps) {
  const { user } = useSessionStore();

  return (
    <Flex
      w="full"
      alignItems="center"
      justifyContent={!collapse ? 'center' : ''}
      gap="0.75rem"
    >
      <IconButton
        aria-label="Logout button"
        icon={<Logout />}
        borderRadius="full"
        color="gray.400"
        variant="ghost"
        fontSize={20}
        onClick={signOut}
        justifyContent="center"
        minW="2.5rem"
        minH="2rem"
      />

      {collapse && (
        <Flex
          w="full"
          flexDirection="column"
          gap={4}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Text
            fontSize="sm"
            fontWeight="400"
            pb="0"
            color="gray.700"
            lineHeight={0}
          >
            {user.name}
          </Text>
          <Text as="small" color="gray.400" fontSize={12} lineHeight={0}>
            {user.email}
          </Text>
        </Flex>
      )}
    </Flex>
  );
}
