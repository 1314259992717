import { create } from 'zustand';

// import { formatDateWithHour, formatElapsedTime } from '@/utils/formatDate';
// import { api } from '@/lib/axios/api';
// import { getStatusTheme } from '@/utils/getStatusTheme';
// import { toast } from 'react-toastify';
import {
  // Event,
  PortalEventState,
  PortalEventActions,
} from '@store/portal-event/types';
import { initialPortalEventState } from '@store/portal-event/initial-state';

export const usePortalEventStore = create<
  PortalEventState & PortalEventActions
>((set) => ({
  ...initialPortalEventState,
  changeCompany: (company) => {
    set({ selectedCompany: company });
  },
  changeCategory: (category) => {
    set({ selectedCategory: category });
  },
  changeNameEvent: (text) => {
    set({ nameEvent: text });
  },
  saveIntervalDates: (date, variant) => {
    set((state) => ({
      intervalDates: {
        initialDate:
          variant === 'initial' ? date : state.intervalDates.initialDate,
        endDate: variant === 'initial' ? state.intervalDates.endDate : date,
      },
    }));
  },
  changeCurrentPage: (page) => {
    set({
      currentPage: page,
    });
  },
}));
