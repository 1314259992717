import { api } from '@/lib/axios/api';
import { formatElapsedTime } from '@/utils/formatDate';
import { useQuery } from '@tanstack/react-query';
import { Response } from './types';

const ONE_MINUTE = 1000 * 60;
const TEN_MINUTES = ONE_MINUTE * 10;

export function useGetOpenedEvents({ currentPage, setCurrentPage }) {
  const query = useQuery({
    queryKey: ['opened-events', currentPage],
    queryFn: async () => {
      const { data } = await api.get<Response>(
        `/event/opened?search=&limit=10&page=${currentPage}`,
      );

      const { events, pagination } = data;

      if (events.length <= 0) {
        return {
          events,
          pagination,
        };
      }

      const parsedOpenedEvents = events.map((event) => {
        return {
          ...event,
          elapsedTime: formatElapsedTime(event.created_at),
        };
      });

      setCurrentPage(pagination.currentPage);

      return {
        events: parsedOpenedEvents,
        pagination,
      };
    },
    staleTime: TEN_MINUTES,
  });

  return query;
}
