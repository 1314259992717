import { Box, Flex, TabPanel, Tag, Text } from '@chakra-ui/react';

import { HeadingOne, HeadingTwo } from '@/components/Heading';
import { SnapEventInfo } from '../SnapEventInfo';
import { useGetEventDetailToUpdate } from '@/hooks/queries/get-event-detail-to-update';
import { Platform } from './Platforms';
import { DigitalService } from '@/store/platform-event/types';
import { useUpdateEventStore } from '@/store/update-event';

export function AlertLocationEquipment() {
  const { queryData } = useGetEventDetailToUpdate();
  const { onToggleModal } = useUpdateEventStore((state) => state);
  const alert = queryData.event.alert;

  return (
    <TabPanel p="0" pb="2rem">
      <Flex
        justifyContent="space-between"
        align="center"
        mb={{ base: '0', lg: '1rem' }}
      >
        <HeadingOne
          text="Atualizando Evento"
          display={{ base: 'none', lg: 'flex' }}
        />
      </Flex>

      <SnapEventInfo />

      <Box mt={{ base: '3rem', lg: '2.625rem' }}>
        <HeadingTwo text="Locais e equipamentos" />

        <Text mt="0.375rem" color="gray.400" fontSize="1rem">
          Informe quais os locais ou equipamentos estão sendo afetados por este
          evento.
        </Text>

        <Box
          border="1px"
          borderColor="#E5E7EA"
          bg="white"
          mt="1.9375rem"
          px="1.125rem"
          rounded="0.5rem"
        >
          <Box _notLast={{ borderBottom: '0.0625rem solid #E5E7EA' }}>
            <Flex align="center" my="1.875rem" justifyContent="space-between">
              <Text fontSize="1rem" fontWeight="400" color="gray.700">
                Bairros
              </Text>
            </Flex>

            {alert && (
              <Flex
                mt="-1.875rem"
                mb="1.875rem"
                rowGap="1rem"
                columnGap="0.25rem"
                flexWrap="wrap"
                align="center"
              >
                {alert.bairros.map((data) => (
                  <Tag
                    key={data}
                    bg="blue.500"
                    color="white"
                    rounded="full"
                    fontSize="1rem"
                  >
                    {data}
                  </Tag>
                ))}
              </Flex>
            )}
          </Box>

          <Box _notLast={{ borderBottom: '0.0625rem solid #E5E7EA' }}>
            <Flex align="center" my="1.875rem" justifyContent="space-between">
              <Text fontSize="1rem" fontWeight="400" color="gray.700">
                Cidades
              </Text>
            </Flex>

            {alert && (
              <Flex
                mt="-1.875rem"
                mb="1.875rem"
                rowGap="1rem"
                columnGap="0.25rem"
                flexWrap="wrap"
                align="center"
              >
                {alert.cidades.map((data) => (
                  <Tag
                    key={data.id_cidade}
                    bg="blue.500"
                    color="white"
                    rounded="full"
                    fontSize="1rem"
                  >
                    {data.display}
                  </Tag>
                ))}
              </Flex>
            )}
          </Box>

          <Box _notLast={{ borderBottom: '0.0625rem solid #E5E7EA' }}>
            <Flex align="center" my="1.875rem" justifyContent="space-between">
              <Text fontSize="1rem" fontWeight="400" color="gray.700">
                CTO
              </Text>
            </Flex>

            {alert && (
              <Flex
                mt="-1.875rem"
                mb="1.875rem"
                rowGap="1rem"
                columnGap="0.25rem"
                flexWrap="wrap"
                align="center"
              >
                {alert.caixa_optica.map((data) => (
                  <Tag
                    key={data.id_caixa_optica}
                    bg="blue.500"
                    color="white"
                    rounded="full"
                    fontSize="1rem"
                  >
                    {data.display}
                  </Tag>
                ))}
              </Flex>
            )}
          </Box>

          <Box _notLast={{ borderBottom: '0.0625rem solid #E5E7EA' }}>
            <Flex align="center" my="1.875rem" justifyContent="space-between">
              <Text fontSize="1rem" fontWeight="400" color="gray.700">
                Interfaces
              </Text>
            </Flex>

            {alert && (
              <Flex
                mt="-1.875rem"
                mb="1.875rem"
                rowGap="1rem"
                columnGap="0.25rem"
                flexWrap="wrap"
                align="center"
              >
                {alert.interfaces_conexao.map((data) => (
                  <Tag
                    key={data.id_interface_conexao}
                    bg="blue.500"
                    color="white"
                    rounded="full"
                    fontSize="1rem"
                  >
                    {data.display}
                  </Tag>
                ))}
              </Flex>
            )}
          </Box>

          {queryData.event.categories.name === 'Informativo' && (
            <Box _notLast={{ borderBottom: '0.0625rem solid #E5E7EA' }}>
              <Platform />

              {queryData.event && (
                <Flex
                  mt="-1.875rem"
                  mb="1.875rem"
                  rowGap="1rem"
                  columnGap="8px"
                  flexWrap="wrap"
                  align="center"
                >
                  {queryData.event.digital_services
                    .slice(0, 5)
                    .map((data: DigitalService) => (
                      <Tag
                        key={data.service_id}
                        bg="blue.500"
                        color="white"
                        rounded="full"
                        fontSize="1rem"
                        px={'0.625rem'}
                      >
                        {data.name}
                      </Tag>
                    ))}

                  {queryData.event.digital_services.length > 5 && (
                    <Tag
                      cursor="pointer"
                      onClick={() =>
                        onToggleModal({
                          modalName: 'platformEvent',
                          isOpen: true,
                        })
                      }
                      bg="white"
                      color="blue.500"
                      rounded="full"
                      fontSize="1rem"
                      _hover={{ bg: 'gray.100' }}
                      height={'1.875rem'}
                    >
                      {'Ver mais'}
                    </Tag>
                  )}
                </Flex>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </TabPanel>
  );
}
