import { KeyboardEvent } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useBreakpointValue,
} from '@chakra-ui/react';
import { AiOutlineSearch } from 'react-icons/ai';

import { usePreviousEventStore } from '@store/previous-event';

interface NameEventSearchProps {
  refetch: () => void;
}

export function NameEventSearch({ refetch }: NameEventSearchProps) {
  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      ssr: false,
    },
  );

  const { nameEvent, changeNameEvent } = usePreviousEventStore(
    (state) => state,
  );

  function onFetchEventsByNameOrCompany(
    event: KeyboardEvent<HTMLInputElement>,
  ) {
    if (event.key === 'Enter' || event.type === 'click') {
      refetch();
    }
  }

  if (isMobile) {
    return (
      <InputGroup
        background="white"
        h="100%"
        w="100%"
        color="gray.400"
        rounded="0.5rem"
      >
        <InputLeftElement h="100%" pointerEvents="none">
          <IconButton
            border="none"
            variant="outline"
            colorScheme="gray"
            aria-label="Search"
            icon={<SearchIcon />}
          />
        </InputLeftElement>

        <Input
          enterKeyHint="search"
          px="0.4rem"
          py="0.4rem"
          type="search"
          name="search"
          placeholder="Pesquisar por nome do evento ou ID Alerta"
          _placeholder={{ color: 'gray.400' }}
          border="none"
          h="100%"
          onChange={(e) => changeNameEvent(e.target.value)}
          onKeyDown={(event) => onFetchEventsByNameOrCompany(event)}
          value={nameEvent}
        />
      </InputGroup>
    );
  }

  return (
    <InputGroup w="21.875rem" border="transparent">
      <InputLeftElement h="100%" pointerEvents="none">
        <AiOutlineSearch color="gray.300" />
      </InputLeftElement>

      <Input
        enterKeyHint="search"
        type="search"
        name="search"
        placeholder="Pesquisar por nome do evento ou ID Alerta"
        _placeholder={{ color: 'gray.400' }}
        h="2.3rem"
        paddingEnd={0}
        onChange={(e) => changeNameEvent(e.target.value)}
        onKeyDown={(event) => onFetchEventsByNameOrCompany(event)}
        value={nameEvent}
        color="gray.400"
      />
    </InputGroup>
  );
}
