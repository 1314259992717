import axios, { AxiosError } from 'axios';
import { parseCookies, setCookie } from 'nookies';

import { AuthTokenError } from './errors/AuthTokenError';

interface AxiosErrorResponse {
  code?: string;
}

type FailedRequestQueue = {
  onSuccess: (token: string) => void;
  onFailure: (error: AxiosError) => void;
};

let isRefreshing = false;
let failedRequestsQueue: FailedRequestQueue[] = [];

function setupClient(ctx = undefined) {
  let cookies = parseCookies(ctx);

  const api = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL_SSO,
    headers: {
      Authorization: `Bearer ${cookies['@event-monitor-web:token-1.0.1']}`,
    },
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError<AxiosErrorResponse>) => {
      if (error.response?.status === 401) {
        if (
          error.response.data?.code === 'token.expired' ||
          error.response.data?.code === 'token.invalid'
        ) {
          cookies = parseCookies(ctx);

          const { '@event-monitor-web:refreshToken-1.0.1': refreshToken } =
            cookies;

          const originalConfig = error.config;

          if (!isRefreshing) {
            isRefreshing = true;

            api
              .post('/session/refresh', {
                refresh_token: refreshToken,
              })
              .then((response) => {
                const { token, refresh_token } = response.data;

                setCookie(ctx, '@event-monitor-web:token-1.0.1', token, {
                  maxAge: 60 * 60 * 24 * 30,
                  path: '/',
                });

                setCookie(
                  ctx,
                  '@event-monitor-web:refreshToken-1.0.1',
                  refresh_token,
                  {
                    maxAge: 60 * 60 * 24 * 30,
                    path: '/',
                  },
                );

                api.defaults.headers.Authorization = `Bearer ${token}`;

                failedRequestsQueue.forEach((request) =>
                  request.onSuccess(token),
                );
                failedRequestsQueue = [];
              })
              .catch((err) => {
                failedRequestsQueue.forEach((request) =>
                  request.onFailure(err),
                );
                failedRequestsQueue = [];
              })
              .finally(() => {
                isRefreshing = false;
              });
          }

          return new Promise((resolve, reject) => {
            failedRequestsQueue.push({
              onSuccess: (token: string) => {
                if (!originalConfig?.headers) {
                  return;
                }

                originalConfig.headers.Authorization = `Bearer ${token}`;

                resolve(api(originalConfig));
              },
              onFailure: (err: AxiosError) => {
                reject(err);
              },
            });
          });
        } else {
          return Promise.reject(new AuthTokenError());
        }
      }

      return Promise.reject(error);
    },
  );

  return api;
}

export const apiSSO = setupClient();
