import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { HiDotsVertical } from 'react-icons/hi';

import { useDeleteHistory } from '@/hooks/mutations/delete-history';
// import { queryClient } from '@/services/queryClient';
import { useQueryClient } from '@tanstack/react-query';

interface MenuProps {
  storyId: string;
}

export function DeleteStoryMenu({ storyId }: MenuProps) {
  const { mutateAsync } = useDeleteHistory();
  const queryClient = useQueryClient();

  async function handleDeleteHistoryEvent(id: string) {
    try {
      await queryClient.invalidateQueries({ queryKey: ['opened-events'] });

      console.log('Query cache invalidated successfully');
    } catch (error) {
      console.error('Failed to invalidate queries:', error);
    }
    await mutateAsync(id);
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HiDotsVertical fontSize="1.2rem" />}
        bg="white"
        rounded="full"
        size="sm"
      />
      <MenuList>
        <MenuItem onClick={() => handleDeleteHistoryEvent(storyId)}>
          Excluir
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
