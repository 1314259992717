import { Flex, Text } from '@chakra-ui/react';
import { Modal } from './Modal';
import { memo } from 'react';

function PlatformComponent() {
  return (
    <Flex align="center" my="1.875rem" justifyContent="space-between">
      <Text fontSize="1rem" fontWeight="400" color="gray.700">
        Plataformas
      </Text>
      <Modal />
    </Flex>
  );
}

export const Platform = memo(PlatformComponent);
