import { formatDistanceToNowStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const getElapsedTime = (date: string) => {
  const distance = formatDistanceToNowStrict(new Date(date), {
    locale: ptBR,
    roundingMethod: 'floor',
  });
  return `há ${distance}`;
};
